import http from "../http-common";
import data from "@/assets/icons/menu/Data.vue";

export default class TrailDataServices {
  async getAllCourses() {
    return await http.get("v1/course/all");
  }
  async getAllCoursesForReport() {
    return await http.get("v1/course/all-course-for-report");
  }

  async getAllActive(PageNumber, PageSize) {
    return http.get(
      `v1/course?PageNumber=${PageNumber}&PageSize=${PageSize}&active=${true}`
    );
  }

  async getAllInactive(PageNumber, PageSize) {
    return http.get(
      `v1/course?PageNumber=${PageNumber}&PageSize=${PageSize}&active=${false}`
    );
  }

  async getAll(PageNumber, PageSize) {
    return http.get(`v1/course?PageNumber=${PageNumber}&PageSize=${PageSize}`);
  }

  async getFinished(PageNumber, PageSize) {
    return http.get(`v1/course/finished?PageNumber=${PageNumber}&PageSize=${PageSize}`);
  }

  async searchAllActive(title) {
    return http.get(`v1/course/course-search?title=${title}&active=${true}`);
  }

  async searchAllInactive(title) {
    return http.get(`v1/course/course-search?title=${title}&active=${false}`);
  }

  async searchtAll(title) {
    return http.get(`v1/course/course-search?title=${title}`);
  }

  async getClassification() {
    return await http.get("v1/course/classification");
  }
  async addTrail(data) {
    return await http.post("v1/course", data);
  }

  async getTrail(courseId) {
    return await http.get(`v1/course/${courseId}`);
  }
  async disabledTrail(courseId) {
    return await http.delete(`v1/course/${courseId}`);
  }
  async finishedTrail(courseId) {
    return await http.post(`v1/course/${courseId}/finished`);
  }

  async emitCertificate(courseId) {
    return await http.post(`v1/course/${courseId}/emit-certificate`);
  }

  async addStudentsInTrail(students, courseId) {
    return await http.post(`v1/course/${courseId}/students`, students);
  }

  async getStudentsInTrail(courseId) {
    return await http.get(`v1/course/${courseId}/students`);
  }

  async editTrail(courseId, data) {
    return await http.put(`v1/course/${courseId}`, data);
  }
  async participateCourse(courseId) {
    return await http.post(`v1/course/${courseId}/enter-as-student`);
  }
  async getAllTraining(courseId) {
    return await http.get(`v1/course/${courseId}/training`);
  }

  async addTraining(courseId, data) {
    return await http.post(`v1/course/${courseId}/training`, data);
  }

  async participateTraining(courseId, trainingId) {
    return await http.post(
      `v1/course/${courseId}/training/${trainingId}/enter-as-student`
    );
  }

  async getTraining(courseId, trainingId) {
    return await http.get(`v1/course/${courseId}/training/${trainingId}`);
  }

  async editTraining(courseId, trainingId, data) {
    return await http.put(`v1/course/${courseId}/training/${trainingId}`, data);
  }

  async getAllContentStudent(courseId, trainingId) {
    return await http.get(
      `v1/course/${courseId}/training/${trainingId}/student`
    );
  }

  async checkConcludeMedia(courseId, trainingId, mediaId) {
    return await http.post(
      `v1/course/${courseId}/training/${trainingId}/student/${mediaId}/conclude`
    );
  }

  async updateReorderContent(courseId, trainingId, data) {
    return await http.put(
      `v1/course/${courseId}/training/${trainingId}/reorder`,
      data
    );
  }

  async getAllContentMedia(courseId, trainingId) {
    return await http.get(`v1/course/${courseId}/training/${trainingId}/media`);
  }
  async addMedia(courseId, trainingId, data, FunctionLoading) {
    return await http.post(
      `v1/course/${courseId}/training/${trainingId}/media`,
      data,
      {
        onUploadProgress: (event) => {
          let progress = Math.round((event.loaded * 100) / event.total);
          FunctionLoading(progress);
        },
      }
    );
  }
  async editMedia(courseId, trainingId, mediaId, data) {
    return await http.put(
      `v1/course/${courseId}/training/${trainingId}/media/${mediaId}`,
      data
    );
  }
  async disabledContentMedia(courseId, trainingId, mediaId) {
    return await http.delete(
      `v1/course/${courseId}/training/${trainingId}/media/${mediaId}`
    );
  }

  async disabledQuiz(courseId, trainingId, quizId) {
    return await http.delete(
      `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}`
    );
  }

  async startQuiz(courseId, trainingId, quizId) {
    return await http.post(
      `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/start`
    );
  }

  async getEvaluation(courseId, trainingId, quizId) {
    return await http.get(
      `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/data`
    );
  }

  async startEvaluation(courseId, trainingId, quizId) {
    return await http.post(
      `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/start-evaluation`
    );
  }
  async answerQuiz(courseId, trainingId, quizId, answerId, data, isAnswered) {
    return await http.post(
      `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/answer/${answerId}?update=${isAnswered}`,
      data
    );
  }
  async scoreQuiz(courseId, trainingId, quizId) {
    return await http.get(
      `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/score`
    );
  }

  async getGame(courseId, trainingId, gameId) {
    return await http.get(
      `v1/course/${courseId}/training/${trainingId}/game/${gameId}`
    );
  }

  async getGameWithOutTraining(gameId) {
    return await http.get(`v1/course/game/${gameId}`);
  }

  async addGame(courseId, trainingId, data) {
    return await http.post(
      `v1/course/${courseId}/training/${trainingId}/game`,
      data
    );
  }

  async editGame(courseId, trainingId, gameId, data) {
    return await http.put(
      `v1/course/${courseId}/training/${trainingId}/game/${gameId}`,
      data
    );
  }
  async disabledContentGame(courseId, trainingId, gameId) {
    return await http.delete(
      `v1/course/${courseId}/training/${trainingId}/game/${gameId}`
    );
  }

  async checkConcludeGame(courseId, trainingId, gameId) {
    return await http.post(
      `v1/course/${courseId}/training/${trainingId}/student/${gameId}/conclude-game`
    );
  }

  async getScore(courseId, trainingId, quizId) {
    return await http.get(
      `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/score`
    );
  }

  async addCategories(data, companyId) {
    return await http.post(
      `v1/course/course-categories-add?companyId=${companyId}`,
      data
    );
  }

  async removeCategories(categoryId) {
    return await http.delete(
      `v1/course/course-categories-disabled?categoryId=${categoryId}`
    );
  }

  async getCategories(companyId) {
    return await http.get(`v1/course/course-categories?companyId=${companyId}`);
  }

  async historyCourse(data) {
    return await http.post(`v1/course/history-course`, data);
  }

  async getAllQuiz(quizid) {
    return http.get(`v1/course/quiz/${quizid}`);
  }

  async getQuizById(quizStudentsId) {
    return http.get(`v1/course/student/${quizStudentsId}/quiz-correction`);
  }

  async correctionOpenQuestion(data) {
    return http.post(
      `v1/course/correction-open-question/
    `,
      data
    );
  }

  async releaseFix(quizId, data) {
    return http.put(
      `v1/course/quiz/${quizId}/update-show-review?showReview=${data}
    `
    );
  }

  async ViewFixedQuestions(quizStudentsId) {
    return http.get(
      `v1/course/student/${quizStudentsId}/quiz-after-correction`
    );
  }

  async deletedUsersOnCourse(data) {
    return http.put("v1/course/delete-user-in-couser", data);
  }

  async deleteGroupsInCourse(data) {
    return http.put("v1/course/delete-group-in-course", data);
  }

  async addGroupsInTrail(data) {
    return http.put("v1/course/insert-group-in-course", data);
  }

  async getAllGroupsInTrail(courseId) {
    return http.get(`v1/course/list-group-in-course?courseId=${courseId}`);
  }

  async deleteTraining(courseId, trainingId) {
    return http.put(
      `v1/course/delete-training?trainingId=${trainingId}&courseId=${courseId}`
    );
  }

  async createLive(data) {
    console.log(data);
    return http.post("v1/course/add-live", data);
  }

  async getTokenLiveClass(form) {
    return http.get(
      `v1/course/get-token?liveClassId=${form.id}&userName=${form.name}`
    );
  }

  async getAllLiveClass(courseId) {
    return http.get(`v1/course/list-liveClass?trainingId=${courseId}`);
  }

  async deleteLiveClasses(data) {
    return http.put("v1/course/delete-liveClass", data);
  }

  async endLesson(liveId) {
    return http.put(`v1/course/close-liveClass?liveClassId=${liveId}`);
  }
  async disconnectLesson(liveId) {
    return http.put(`v1/course/exit-liveClass?liveClassId=${liveId}`);
  }

  async enterCourse(courseId) {
    return http.post(`v1/course/start-group-course?courseId=${courseId}`);
  }

}
